body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  font-family: "Outfit", serif;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-message {
  .ant-message-notice-content {
    
    flex-direction: row;
    border: 2px solid;
    background: #FFFBE3;
    border-radius: 24px !important;
    padding: 14px 0;
    width: 100%;
    color: #FF9D00;
    background: #fcf3b7 !important;

    .ant-message-custom-content {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    color: #000;

    }
  }
}

.border-item {
  ::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 20px;
    padding: 2px;
    background:linear-gradient(135deg,#F6D610,#FF9D00);
    mask:
        linear-gradient(#000 0 0) content-box,
        linear-gradient(#000 0 0);
    mask-composite: exclude;
  }
}

/* @font-face {
  font-family: "SF-Pro";
  src: local("SF-Pro"), url("../src/assets/fonts/SF-Pro.ttf") format("truetype");
  font-weight: bold
} */
